.bannerPage {
    background-image: url("../../images/bannerBackground.png");
    background-repeat: no-repeat;
    max-height: 1080px;
    min-width: 100%;
    max-width: 1920px;
    min-height: auto;
    background-size: contain;
    aspect-ratio: 16 / 9;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bannerPage .allFlags {
    display: flex;
}

.bannerPage .flagContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bannerPage .flagContainer:first-child {
    /*We dont use gap here, instead we manually set space between, montenegro name is much bigger                                        
                                                 so we need to set like this so we can have exactly same space between all flags*/
    margin-right: 60px;
}

.bannerPage .flagContainer:nth-child(2) {
    margin-right: 35px;
}

.bannerPage .flagContainer:nth-child(3) {
    margin-right: 35px;
}

.bannerPage .flagContainer a {
    margin-bottom: 37px;
    width: max-content;
    max-height: max-content;
}

.bannerPage h1 {
    margin-top: 185px;
    margin-bottom: 185px;
    font-size: 7.625rem;  
    font-family: Raleway;
    color: rgb(237, 28, 36);
    vertical-align: super;
    line-height: 0.6;
    font-weight: 800;
    font-style: normal;

  -webkit-text-fill-color: rgb(237, 28, 36); /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 6px;
  -webkit-text-stroke-color: #511218;
}

.bannerPage img {

    max-width: max-content;
    max-height: max-content;
}

.bannerPage img:hover {
    cursor: pointer;
    filter: brightness(0.8);
}

.bannerPage .disabled {
    filter: grayscale(100%) opacity(0.5);
    pointer-events: none;
}

.bannerPage .countryName {
    font-family: Raleway;
    font-size: 2.8rem;
    font-weight: 800;
    font-style: normal;
    color: rgb(81, 18, 24);
    vertical-align: super;
    line-height: 0.6;
}

@media (max-width: 1720px) {
    .bannerPage .flagContainer:nth-child(2) {
        margin-right: 50px;
    }

    .bannerPage .flagContainer:nth-child(3) {
        margin-right: 50px;
    }

    .bannerPage h1 {
        margin-top: 155px;
        margin-bottom: 155px;
    }
}

@media (max-width: 1500px) {
    .bannerPage h1 {
        margin-top: 125px;
        margin-bottom: 125px;
        -webkit-text-stroke-width: 5px;
    }

    .bannerPage .flagContainer a {
        margin-bottom: 30px;
    }


    .bannerPage img {
        max-width: 230px;
        max-height: 230px;
    }
}

@media (max-width: 1250px) {
    .bannerPage .flagContainer:first-child {
        margin-right: 45px;
    }

    .bannerPage .flagContainer:nth-child(2) {
        margin-right: 26px;
    }

    .bannerPage .flagContainer:nth-child(3) {
        margin-right: 26px;
    }

    .bannerPage h1 {
        margin-top: 120px;
        margin-bottom: 80px;
    }

    .bannerPage .flagContainer a {
        margin-bottom: 25px;
    }

    .bannerPage img {
        max-width: 200px;
        max-height: 200px;
    }
}

@media (max-width: 1000px) {
    .bannerPage .flagContainer:first-child {
        margin-right: 35px;
    }

    .bannerPage .flagContainer:nth-child(2) {
        margin-right: 10px;
    }

    .bannerPage .flagContainer:nth-child(3) {
        margin-right: 5px;
    }

    .bannerPage h1 {
        margin-top: 100px;
        margin-bottom: 80px;
    }

    .bannerPage .flagContainer a {
        margin-bottom: 20px;
    }


    .bannerPage img {
        max-width: 170px;
        max-height: 170px;
    }
}

@media (max-width: 874px) {
    .bannerPage {
        background-image: url("../../images/mobileBannerBackground.png");
        padding-left: 106px;
        padding-right: 106px;
        box-sizing: border-box;
        max-width: 874px;
        min-height: 100vh;
        max-height: none;
        height: auto;
        width: 100%;
        background-size: cover;
        aspect-ratio: 9 / 20;
    }

    .bannerPage .allFlags {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .bannerPage .flagContainer:first-child {
        margin-right: 0;
    }

    .bannerPage .flagContainer:nth-child(2) {
        margin-right: 0;
    }

    .bannerPage .flagContainer:nth-child(3) {
        margin-right: 0;
    }

    .bannerPage .flagContainer {
        min-width: 295px;
        margin-bottom: 57px;
    }

    .bannerPage h1 {
        margin-top: 350px;
        margin-bottom: 187px;
        font-size: 5.5rem;
        vertical-align: super;
        line-height: 1;
        text-align: center;
        -webkit-text-stroke-width: 4px;
    }

    .bannerPage .flagContainer a {
        margin-bottom: 52px;
    }

    .bannerPage img {
        max-width: max-content;
        max-height: max-content;
    }

    .bannerPage .countryName {
        font-size: 2.6rem;
    }
}


@media (max-width: 830px) {
    .bannerPage {
        padding-left: 56px;
        padding-right: 56px;
    }
}

@media (max-width: 730px) {
    .bannerPage {
        padding-left: 25px;
        padding-right: 25px;
    }

    .bannerPage img {
        max-width: 230px;
        max-height: 230px;
    }

    .bannerPage .flagContainer a {
        margin-bottom: 30px;
    }


    .bannerPage .flagContainer {
        min-width: 260px;
        margin-bottom: 37px;
    }

    .bannerPage h1 {
        margin-top: 250px;
        margin-bottom: 147px;
    }
}

@media (max-width: 590px) {
    .bannerPage {
        padding-left: 15px;
        padding-right: 15px;
    }

    .bannerPage .flagContainer a {
        margin-bottom: 25px;
    }

    .bannerPage img {
        max-width: 200px;
        max-height: 200px;
    }

    .bannerPage .flagContainer {
        min-width: 241px;
        margin-bottom: 37px;
    }

    .bannerPage h1 {
        margin-top: 180px;
        margin-bottom: 107px;
    }
}

@media (max-width: 530px) {
    .bannerPage {
        padding-left: 5px;
        padding-right: 5px;
    }

    .bannerPage img {
        max-width: 150px;
        max-height: 150px;
    }

    .bannerPage .flagContainer a {
        margin-bottom: 15px;
    }


    .bannerPage .flagContainer {
        min-width: 203px;
    }

    .bannerPage h1 {
        margin-top: 120px;
        margin-bottom: 87px;
    }
}

@media (max-width: 430px) {
    .bannerPage .flagContainer {
        min-width: 173px;
    }

    .bannerPage .countryName {
        font-size: 2.2rem;
    }

    .bannerPage .allFlags {
        justify-content: space-around;
    }
}

@media (max-width: 360px) {
    .bannerPage .flagContainer {
        min-width: 154px;
    }

    .bannerPage .countryName {
        font-size: 2rem;
    }

    .bannerPage img {
        max-width: 140px;
        max-height: 140px;
    }
}