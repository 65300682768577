body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  width: 100%;
  max-width: 1920px;
}

@font-face {
  font-family: "Raleway";
  src: url("./fonts/Raleway-Heavy.ttf") format("truetype");
   font-weight: 800;
   font-style: normal;
 }

 @media (max-width: 1720px) {
  html{
    font-size: 14px;
  }
 }

 @media (max-width: 1500px) {
  html{
    font-size: 13px;
  }
 }

 @media (max-width: 1250px) {
  html{
    font-size: 12px;
  }
 }

 @media (max-width: 1000px) {
  html{
    font-size: 11px;
  }
 }

 @media (max-width: 874px) {
  html{
    font-size: 16px;
  }
 }

 @media (max-width: 730px) {
  html{
    font-size: 14px;
  }
 }

 @media (max-width: 590px) {
  html{
    font-size: 13px;
  }
 }

 @media (max-width: 530px) {
  html{
    font-size: 11px;
  }
 }

