.prizes {
    background-image: url("../../images/soonBackground.png");
    background-repeat: no-repeat;
    max-height: 1080px;
    min-width: 100%;
    max-width: 1920px;
    min-height: auto;
    background-size: contain;
    aspect-ratio: 16 / 9;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
}

.prizes .scoreBoard {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 647px;
    max-height: 140px;
    z-index: 5;
}

.prizes .allPrizes {
    display: flex;
    margin-bottom: 70px;
    justify-content: space-between;
    width: 100%;
    padding-left: 230px;
    padding-right: 170px;
}


.prizes .prize {
    display: flex;
    border: 3px solid rgb(237, 28, 36);
    border-radius: 43px;
    background-color: rgba(237, 28, 36, 0.569);
    width: 318px;
    height: 568px;
    font-size: 1.3rem;
    font-family: Raleway;
    font-weight: 800;
    font-style: normal;
    vertical-align: super;
    line-height: 1.2;
    color: rgb(255, 255, 255);
    text-align: center;
    padding-left: 6px;
    padding-right: 6px;
    box-sizing: border-box;
    position: relative;
}

.prizes .prize img {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
}

.prizes .prize1 {
    padding-top: 200px;
}

.prizes .prize1 img {
    left: 50%;
    top: 0;
    transform: translate(-47%, -52%);
}

.prizes .prize2 {
    padding-top: 200px;
}

.prizes .prize2 img {
    left: 50%;
    top: 0;
    transform: translate(-56%, -50%);
}

.prizes .prize3 {
    padding-top: 173px;
}

.prizes .prize3 img {
    left: 50%;
    top: 0;
    transform: translate(-55%, -60%);
}

.prizes .prize4 {
    padding-top: 260px;
}

.prizes .prize4 img {
    left: 50%;
    top: 0;
    transform: translate(-54%, -55%);
}

@media (max-width: 1860px) {
    .prizes .allPrizes {
        display: flex;
        margin-bottom: 50px;
        padding-left: 170px;
        padding-right: 120px;
    }
}

@media (max-width: 1760px) {
    .prizes .prize1 img {
        transform: translate(-47%, -52%) scale(0.8);
    }

    .prizes .prize2 img {
        transform: translate(-56%, -50%) scale(0.8);
    }

    .prizes .prize3 img {
        transform: translate(-55%, -60%) scale(0.8);
    }

    .prizes .prize4 img {
        transform: translate(-54%, -55%) scale(0.8);
    }

    .prizes .scoreBoard {
        transform: translateX(-50%) scale(0.8);
        bottom: -10px;
    }

    .prizes .prize1 {
        padding-top: 170px;
    }

    .prizes .prize2 {
        padding-top: 170px;
    }

    .prizes .prize3 {
        padding-top: 153px;
    }

    .prizes .prize4 {
        padding-top: 200px;
    }

    .prizes .prize {
        height: 500px;
    }

}

@media (max-width: 1700px) {
    .prizes .prize1 img {
        transform: translate(-47%, -52%) scale(0.7);
    }

    .prizes .prize2 img {
        transform: translate(-56%, -50%) scale(0.7);
    }

    .prizes .prize3 img {
        transform: translate(-55%, -60%) scale(0.7);
    }

    .prizes .prize4 img {
        transform: translate(-54%, -55%) scale(0.7);
    }

    .prizes .prize1 {
        padding-top: 130px;
    }

    .prizes .prize2 {
        padding-top: 130px;
    }

    .prizes .prize3 {
        padding-top: 113px;
    }

    .prizes .prize4 {
        padding-top: 170px;
    }

    .prizes .prize {
        height: 425px;
    }

}

@media (max-width: 1600px) {
    .prizes .prize {
        width: 290px;
    }

    .prizes .allPrizes {
        margin-bottom: 35px;
        padding-left: 120px;
        padding-right: 80px;
    }

}

@media (max-width: 1400px) {
    .prizes .prize1 img {
        transform: translate(-47%, -52%) scale(0.6);
    }

    .prizes .prize2 img {
        transform: translate(-56%, -50%) scale(0.6);
    }

    .prizes .prize3 img {
        transform: translate(-55%, -60%) scale(0.6);
    }

    .prizes .prize4 img {
        transform: translate(-54%, -55%) scale(0.6);
    }

    .prizes .prize1 {
        padding-top: 110px;
    }

    .prizes .prize2 {
        padding-top: 110px;
    }

    .prizes .prize3 {
        padding-top: 93px;
    }

    .prizes .prize4 {
        padding-top: 140px;
    }

    .prizes .prize {
        height: 425px;
        width: 260px;
    }

    .prizes .allPrizes {
        padding-right: 40px;
    }

}

@media (max-width: 1250px) {
    .prizes .prize {
        height: 345px;
        width: 260px;
    }

    .prizes .allPrizes {
        padding-right: 20px;
        padding-left: 20px;
    }

    .prizes .scoreBoard {
        transform: translateX(-50%) scale(0.65);
        bottom: -20px;
    }

}

@media (max-width: 1150px) {
    .prizes .prize1 img {
        transform: translate(-47%, -52%) scale(0.5);
    }

    .prizes .prize2 img {
        transform: translate(-56%, -50%) scale(0.5);
    }

    .prizes .prize3 img {
        transform: translate(-55%, -60%) scale(0.5);
    }

    .prizes .prize4 img {
        transform: translate(-54%, -55%) scale(0.5);
    }

    .prizes .prize1 {
        padding-top: 93px;
    }

    .prizes .prize2 {
        padding-top: 93px;
    }

    .prizes .prize3 {
        padding-top: 66px;
    }

    .prizes .prize4 {
        padding-top: 115px;
    }

    .prizes .prize {
        height: 330px;
        width: 245px;
    }

    .prizes .allPrizes {
        padding-right: 10px;
        padding-left: 10px;
    }

}

@media (max-width: 1000px) {
    .prizes .prize1 img {
        transform: translate(-47%, -52%) scale(0.4);
    }

    .prizes .prize2 img {
        transform: translate(-56%, -50%) scale(0.4);
    }

    .prizes .prize3 img {
        transform: translate(-55%, -60%) scale(0.4);
    }

    .prizes .prize4 img {
        transform: translate(-54%, -55%) scale(0.4);
    }

    .prizes .prize1 {
        padding-top: 75px;
    }

    .prizes .prize2 {
        padding-top: 75px;
    }

    .prizes .prize3 {
        padding-top: 46px;
    }

    .prizes .prize4 {
        padding-top: 85px;
    }

    .prizes .prize {
        height: 300px;
        width: 215px;
    }

    .prizes .scoreBoard {
        transform: translateX(-50%) scale(0.5);
        bottom: -30px;
    }

    .prizes .allPrizes {
        margin-bottom: 17px;
    }

}


@media (max-width: 874px) {
    .prizes {
        background-image: url("../../images/mobilePrizesBackground.png");
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
        max-width: 874px;
        min-height: 100vh;
        max-height: none;
        height: auto;
        width: 100%;
        background-size: cover;
        aspect-ratio: 9 / 20;
    }
}