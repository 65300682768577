.votePage{
    background-image: url("../../images/voteBackground.png");
    background-repeat: no-repeat;
    max-height: 1080px;
    min-width: 100%;
    max-width: 1920px;
    min-height: auto;
    background-size: contain;
    aspect-ratio: 16 / 9;
    display: flex;
    align-items: center;
    padding-bottom: 100px;
    box-sizing: border-box;
}

.votePage .chooseTeam{
    margin-bottom: 0;
    margin-top: auto;
}
.votePage .beefChipsy{
    margin-bottom: 71px;
    margin-top: auto;
}