.header{
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    max-width: 1920px;
    top: 0;
    z-index: 5;
}
.header img{
    margin-left: 22px;
    margin-top: 18.5px;
}
.header nav{
    border: 3px solid rgb(237, 28, 36);
    border-radius: 50px;
    background-color: rgba(237, 28, 36, 0.569);
    width: 100%;
    height: max-content;
    margin-right: 62px;
    margin-top: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 80px;
}

.header a{
    color: #ffc515;
    font-family: Raleway;
    font-size: 3rem;
    vertical-align: super;
    line-height: 0.6;
    text-decoration: none;
}
.header a:hover{
    color: #FFF;
}

.header a.active{
    text-decoration: underline;
    text-underline-position: under;
    color: rgb(255, 255, 255);
}


@media (max-width: 1800px) {
    .header nav{
        padding-left: 30px;
        padding-right: 30px;
        margin-right: 32px;
    }
}


@media (max-width: 1500px) {
    .header img{
        width: 226px;
        height: 233px;
    }
    .header a{
        font-size: 2.5rem;
    }
}

@media (max-width: 1350px) {
    .header img{
        width: 173px;
        height: 177px;
    }
    .header a{
        font-size: 2.5rem;
    }
}

@media (max-width: 1150px) {
    .header a{
        font-size: 2rem;
    }
    .header nav{
        padding-left: 15px;
        padding-right: 15px;
    }
    .header img{
        width: 143px;
        height: 146px;
    }
}

@media (max-width: 874px) {
    .header{
        display: none;
    }
}

  