.comingSoon {
    background-image: url("../../images/soonBackground.png");
    background-repeat: no-repeat;
    max-height: 1080px;
    min-height: auto;
    min-width: 100%;
    max-width: 1920px;
    background-size: contain;
    aspect-ratio: 16 / 9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.comingSoon .logoContainer {
    display: flex;
    justify-content: space-between;
    gap: 272px;
    width: max-content;
    margin-bottom: 25px;
}

.comingSoon .text {
    font-family: Raleway;
    color: rgb(237, 28, 36);
    line-height: 1;
    font-weight: 800;
    font-style: normal;
    -webkit-text-fill-color: rgb(237, 28, 36);
    /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 6px;
    -webkit-text-stroke-color: #511218;
}

.comingSoon .text1 {
    font-size: 7.625rem;
    -webkit-text-fill-color: rgb(237, 28, 36);
    /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 5px;
    -webkit-text-stroke-color: #511218;
}

.comingSoon .text2 {
    font-size: 11.25rem;
    margin-bottom: 40px;
}

.comingSoon .text3 {
    font-size: 3.56rem;
    -webkit-text-fill-color: rgb(237, 28, 36);
    /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #511218;
}

@media (max-width: 1500px) {
    .comingSoon .beefImg {
        max-width: 268px;
        max-height: 220px;
    }

    .comingSoon .cheeseImg {
        max-width: 220x;
        max-height: 220px;
    }

    .comingSoon .text1 {
        font-size: 6.5rem;
        -webkit-text-stroke-width: 4px;
    }

    .comingSoon .text2 {
        font-size: 9.56rem;
        -webkit-text-stroke-width: 5px;
    }

    .comingSoon .text3 {
        font-size: 3rem;
        -webkit-text-stroke-width: 3px;
    }
}

@media (max-width: 1250px) {
    .comingSoon .beefImg {
        max-width: 241px;
        max-height: 198px;
    }

    .comingSoon .cheeseImg {
        max-width: 198px;
        max-height: 198px;
    }
}

@media (max-width: 1080px) {
    .comingSoon .beefImg {
        max-width: 217px;
        max-height: 180px;
    }

    .comingSoon .cheeseImg {
        max-width: 180x;
        max-height: 180px;
    }

    .comingSoon .text1 {
        font-size: 5.45rem;
    }

    .comingSoon .text2 {
        font-size: 8.45rem;
    }

    .comingSoon .text3 {
        font-size: 2.6rem;
    }
}


@media (max-width: 874px) {
    .comingSoon {
        display: none;
    }
}