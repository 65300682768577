.mobileComingSoon {
    display: none;
}

@media (max-width: 874px) {
    .mobileComingSoon {
        display: block;
        background-image: url("../../images/mobileSoonBackground.png");
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
        max-width: 874px;
        min-height: 100vh;
        max-height: none;
        height: auto;
        width: 100%;
        background-size: cover;
        aspect-ratio: 9 / 20;
    }

    .mobileComingSoon .beefImg {
        margin-top: 161px;
        margin-bottom: 105px;
    }

    .mobileComingSoon .text {
        font-family: Raleway;
        color: rgb(237, 28, 36);
        line-height: 1;
        font-weight: 800;
        font-style: normal;
        -webkit-text-fill-color: rgb(237, 28, 36);
        /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 4px;
        -webkit-text-stroke-color: #511218;
    }

    .mobileComingSoon .text1 {
        font-size: 5.18rem;
        -webkit-text-fill-color: rgb(237, 28, 36);
        /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 3px;
        -webkit-text-stroke-color: #511218;
    }

    .mobileComingSoon .text2 {
        font-size: 7.65rem;
        margin-bottom: 40px;
    }

    .mobileComingSoon .text3 {
        font-size: 2.43rem;
        -webkit-text-fill-color: rgb(237, 28, 36);
        /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #511218;
        margin-bottom: 120px;
    }
}

@media (max-width: 840px) {
    .mobileComingSoon .text1 {
        font-size: 4.2rem;
    }

    .mobileComingSoon .text2 {
        font-size: 6.2rem;
    }

    .mobileComingSoon .text3 {
        font-size: 2rem;
    }

    .mobileComingSoon .cheeseImg {
        max-width: 447px;
        max-height: 444px;
    }

    .mobileComingSoon .beefImg {
        max-width: 540px;
        max-height: 425px;
    }
}

@media (max-width: 750px) {
    .mobileComingSoon .text1 {
        font-size: 3.78rem;
    }

    .mobileComingSoon .text2 {
        font-size: 5.6rem;
    }

    .mobileComingSoon .text3 {
        font-size: 1.8rem;
    }

    .mobileComingSoon .cheeseImg {
        max-width: 360px;
        max-height: 358px;
    }

    .mobileComingSoon .beefImg {
        max-width: 436px;
        max-height: 339px;
        margin-top: 100px;
        margin-bottom: 80px;
    }
}

@media (max-width: 600px) {
    .mobileComingSoon {
        background-size: contain;
        background-repeat: no-repeat;
        aspect-ratio: 9.2 / 20;
    }

    .mobileComingSoon .text1 {
        font-size: 3.78rem;
        -webkit-text-stroke-width: 2px;
    }

    .mobileComingSoon .text2 {
        font-size: 5.6rem;
        margin-bottom: 20px;
        -webkit-text-stroke-width: 3px;
    }

    .mobileComingSoon .text3 {
        font-size: 1.8rem;
        margin-bottom: 60px;
        -webkit-text-stroke-width: 1px;
    }

    .mobileComingSoon .cheeseImg {
        max-width: 288px;
        max-height: 286px;
    }

    .mobileComingSoon .beefImg {
        max-width: 348px;
        max-height: 271px;
        margin-top: 70px;
        margin-bottom: 50px;
    }
}

@media (max-width: 470px) {
    .mobileComingSoon .text1 {
        font-size: 3.38rem;
    }

    .mobileComingSoon .text2 {
        font-size: 5rem;
        margin-bottom: 20px;
    }

    .mobileComingSoon .text3 {
        font-size: 1.62rem;
        margin-bottom: 60px;
    }

    .mobileComingSoon .cheeseImg {
        max-width: 260px;
        max-height: 259px;
    }

    .mobileComingSoon .beefImg {
        max-width: 315px;
        max-height: 244px;
        margin-top: 70px;
        margin-bottom: 50px;
    }
}

@media (max-width: 420px) {
    .mobileComingSoon .text1 {
        font-size: 3.04rem;
    }

    .mobileComingSoon .text2 {
        font-size: 4.5rem;
        margin-bottom: 20px;
    }

    .mobileComingSoon .text3 {
        font-size: 1.46rem;
        margin-bottom: 60px;
    }

    .mobileComingSoon .cheeseImg {
        max-width: 221px;
        max-height: 220px;
    }

    .mobileComingSoon .beefImg {
        max-width: 269px;
        max-height: 207px;
        margin-top: 50px;
        margin-bottom: 30px;
    }
}

@media (max-width: 365px) {
    .mobileComingSoon .text1 {
        font-size: 2.76rem;
    }

    .mobileComingSoon .text2 {
        font-size: 4.05rem;
    }

    .mobileComingSoon .text3 {
        font-size: 1.31rem;
        margin-bottom: 30px;
    }
}